var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qSWMgewAKE_HPWx8VoRLd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs';

const SENTRY_DSN = (process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN) as string;

if (process.env.NODE_ENV === 'production') {
  // NOTE: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  init({
    dsn: SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    tracesSampleRate: 0.1,
  });
}
